import axios from 'axios';
import tw from "twin.macro";

import React, { useState, useEffect } from 'react';
import {Select, Textarea, TextInput, Alert} from 'flowbite-react';
import {customTextInputTheme} from "../flowbiteCustomThemes/textinput";
import {customSelectTheme} from "../flowbiteCustomThemes/select";
import {BASE_URL} from "../../index";

const PrimaryButton = tw.button`font-bold px-8 lg:px-10 w-full py-3 rounded bg-primary-500 text-gray-100 focus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const LoadingBar = ({ progress }) => {
    const barStyles = {
        width: `${progress}%`,
        height: "7px",
        backgroundColor: "#00719c", // Change this to your desired color
        transition: "width 0.3s ease-in-out",
        top: 0,
        left: 0,
        position: "fixed", // Set the position to fixed
        zIndex: 9999, // Ensure it's above other elements
    };

    return <div style={barStyles} />;
};
function AppointmentForm({ selectedTime }) {
    const [userDetails, setUserDetails] = useState({
        name: '',
        email: '',
        appointment_type_id: '',
        comment: '',
    });

    const [appointmentTypes, setAppointmentTypes] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [loading, setLoading] = useState(false); // New state for loading indicator
    const [progress, setProgress] = useState(0); // Progress state for the loading bar

    useEffect(() => {
        // Fetch appointment types from your backend API
        axios.get(`${BASE_URL}/api/appointment-types`)
            .then((response) => {
                setAppointmentTypes(response.data);
            })
            .catch((error) => {
                console.error('Error fetching appointment types:', error);
            });
    }, []);

    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
        setUserDetails({
            ...userDetails,
            appointment_type_id: e.target.value,
        });
    };

    const handleInputChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true on form submission

        try {
            // Simulating progress with setTimeout, replace with actual async call
            let currentProgress = 0;
            const interval = setInterval(() => {
                currentProgress += 15;
                setProgress(currentProgress);
                if (currentProgress >= 100) {
                    clearInterval(interval);
                }
            }, 500);

            await axios.post(`${BASE_URL}/api/send-quotation`, userDetails);
            setAlertColor('success');
            setAlertTitle('Success!');
            setAlertMessage('Je aanvraag is verzonden!');
            setShowAlert(true);
            // Add logic for further handling, e.g., displaying success message, redirecting, etc.

        } catch (error) {
            setAlertColor('failure');
            setAlertTitle('Error!');
            setAlertMessage('Er is iets fout gegaan tijdens het versturen, probeer het later opnieuw.');
            setShowAlert(true);
            // Handle error cases, e.g., show error message to the user
        } finally {
            setLoading(false); // Set loading to false after form submission completes
            setProgress(0); // Reset progress
        }
    };

    return (
        <>
            {loading && <LoadingBar progress={progress} />}
            <div className="w-full max-w-sm my-10 relative z-[1000]">
                <div className="fixed m-3 top-0 left-0 right-0">
                    {showAlert && (
                        <Alert color={alertColor} withBorderAccent onDismiss={() => setShowAlert(false)}>
                            <span className={'font-medium'}>{alertTitle} </span>{alertMessage}
                        </Alert>
                    )}
                </div>
            </div>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <TextInput
                        theme={customTextInputTheme}
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Je naam"
                        onChange={handleInputChange}
                        shadow
                        required
                    />
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <TextInput
                        theme={customTextInputTheme}
                        id="email"
                        type="email"
                        name="email"
                        placeholder="je@email.nl"
                        onChange={handleInputChange}
                        required
                        shadow
                    />
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <Select
                        theme={customSelectTheme}
                        color={"gray"}
                        id="appointmentType"
                        value={selectedType}
                        onChange={handleTypeChange}
                        required>
                        <option value="">
                            Selecteer een dienst...
                        </option>
                        {appointmentTypes.map((type) => (
                            <option key={type.id} value={type.id}>{type.name}</option>
                        ))}
                    </Select>
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <Textarea
                            id="user_comment"
                            name="comment"
                            rows="4"
                            onChange={handleInputChange}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Laat je bericht achter en eventueel nog telefoonnummer">
                    </Textarea>
                </div>
            </div>
           <PrimaryButton type="submit" tw={"w-full!"}>Verstuur aanvraag</PrimaryButton>
        </form>
        </>
    );
}

export default AppointmentForm;
