import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/mail.jpeg";
import axios from "axios";
import {BASE_URL} from "../../index";
import {Alert} from "flowbite-react";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`
const LoadingBar = ({ progress }) => {
  const barStyles = {
    width: `${progress}%`,
    height: "7px",
    backgroundColor: "#00719c", // Change this to your desired color
    transition: "width 0.3s ease-in-out",
    top: 0,
    left: 0,
    position: "fixed", // Set the position to fixed
    zIndex: 9999, // Ensure it's above other elements
  };

  return <div style={barStyles} />;
};
export default ({
  subheading = "Contacteer Ons",
  heading = <>Voel je vrij om <span tw="text-primary-500">in contact te komen</span><wbr/> met ons.</>,
  description = "Heb je verder nog vragen of is iets niet duidelijk, kan je ons altijd bellen of hieronder een bericht achterlaten.",
  submitButtonText = "Verstuur",
  formAction = `${BASE_URL}/send-email`,
  formMethod = "POST",
  textOnLeft = true,
}) => {
  const [loading, setLoading] = useState(false); // New state for loading indicator
  const [progress, setProgress] = useState(0); // Progress state for the loading bar

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertColor, setAlertColor] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true on form submission

    try {
      // Simulating progress with setTimeout, replace with actual async call
      let currentProgress = 0;
      const interval = setInterval(() => {
        currentProgress += 15;
        setProgress(currentProgress);
        if (currentProgress >= 100) {
          clearInterval(interval);
        }
      }, 500);

      const response = await axios.post(formAction, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setAlertColor('success');
        setAlertTitle('success!');
        setAlertMessage('Email is verstuurd!');
        setShowAlert(true);
        // Reset form after successful submission
        setFormData({ email: "", name: "", subject: "", message: "" });
      } else {
        setAlertColor('failure');
        setAlertTitle('fout!');
        setAlertMessage('Er is iets fout gegaan tijdens het versturen, probeer het later opnieuw.');
        setShowAlert(true);
      }
    } catch (error) {
      setAlertColor('warning');
      setAlertTitle('Waarschuwing!');
      setAlertMessage('Vul alle benodigde velden in.');
      setShowAlert(true);
    } finally {
      setLoading(false); // Set loading to false after form submission completes
      setProgress(0); // Reset progress
    }
  };

  return (
      <>
        <div className="w-full max-w-sm my-10 relative z-[1000]">
          <div className="fixed m-3 top-0 left-0 right-0">
            {showAlert && (
                <Alert color={alertColor} withBorderAccent onDismiss={() => setShowAlert(false)}>
                  <span className={'font-medium'}>{alertTitle} </span>{alertMessage}
                </Alert>
            )}
          </div>
        </div>
      <Container id={"contact"}>
        {loading && <LoadingBar progress={progress} />}
        <TwoColumn>
          <ImageColumn>
            <Image imageSrc={EmailIllustrationSrc} />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
              {description && <Description>{description}</Description>}
              <Form action={formAction} method={formMethod} onSubmit={handleSubmit}>
                <Input type="email" name="email" placeholder="Je email-adres" value={formData.email} onChange={handleInputChange} />
                <Input type="text" name="name" placeholder="Volledige naam" value={formData.name} onChange={handleInputChange} />
                <Input type="text" name="subject" placeholder="Onderwerp" value={formData.subject} onChange={handleInputChange} />
                <Textarea name="message" placeholder="Je vraag en eventueel telefoonnummer" value={formData.message} onChange={handleInputChange} />
                <SubmitButton type="submit">{submitButtonText}</SubmitButton>
              </Form>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    </>
  );
};
